<template>
    <section class="blockElement marketPortfolio">
        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
        <div class="chartIQ" :id="'AssetChart'+store.assetDetail.id" style="height: 300px; width: 100%;"></div>
        <ul class="d-flex align-items-center justify-content-evenly">
            <li v-for="list in dateDrop" :key="list.key">
                <a class="f-14 gray selectDay" @click.prevent="dateDropSelected = list;changeChart(dateDropSelected.key)" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
            </li>
        </ul>
        
        
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    //import PriceFeed from "@/store/stomp";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import moment from "moment";
   // import buySell from './buy-sell/buy-sell.vue'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                taskTab: "Market",
                CurrencyID: "",
                errImage: [],
                dateDrop: [
                    { key: 1, value: "1D" },
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "YTD" },
                ],
                dateDropSelected: { key: 365, value: "1Y" },
                tradeType: "open",
                openPopup : '',
                detail : {},
                showLoginPopup: false,
                showNews : {}

            };
        },
       
        watch:{
            '$route.params.id'(){
                if(this.$route.name == 'mobileMarket'){
                    this.getCurrecnyDetail();
                }
            },
            '$route.query.access_token'(){
                this.store.$patch({ user: { access_token: this.$route.query.access_token } });
            },  
        },
        methods: {
            changeChart(key){
                if(Object.keys(this.store.assetDetail).length){
                    let chart = []
                    if(key == 1){
                        if(this.store.assetDetail.hourlyChart && this.store.assetDetail.hourlyChart.length){
                            this.store.assetDetail.hourlyChart.map(i =>{
                                let temp = {
                                    date: new Date((moment(`${i.datetime[0]}-${i.datetime[1]}-${i.datetime[2]} ${i.datetime[3]}:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString())).getTime(),
                                    value: i.priceClose
                                }
                                chart.push(temp)
                            })
                            this.drawMarketChart(`AssetChart${this.store.assetDetail.id}`, chart);
                        }
                    }else{
                        if(this.store.assetDetail.dailyChart && this.store.assetDetail.dailyChart.length){
                             this.store.assetDetail.dailyChart.map(i =>{
                                let temp = {
                                date: new Date(moment(`${i.datetime[0]}-${i.datetime[1]}-${i.datetime[2]}`, 'YYYY-MM-DD').format('YYYY-MM-DD')).getTime(),
                                value: i.priceClose
                                }
                                chart.push(temp)
                            }) 
                            if(key == 7){
                                chart = chart.slice(-5)
                            }else if(key == 30){
                                chart = chart.slice(-30)
                            }else if(key == 365){
                                chart = chart.slice(-365)
                            }else if(key == 10000){
                                let startDate = moment().startOf('year');
                                let endDate = moment();
                                let data = [];
                                chart.map((i) =>{
                                    if(moment(i.date).isBetween(startDate, endDate)){
                                        data.push(i)
                                    }
                                })
                                chart = data
                            } 
                            this.drawMarketChart(`AssetChart${this.store.assetDetail.id}`, chart);                                                            
                        }
                    }
                }
            },
            
            getCurrecnyDetail() {
                let formData = {
                    from: "",
                    to: "",
                    marketIds: [this.$route.params.id.split("&")[0]],
                };
                formData["from"] = moment().subtract(1, "year").format("YYYY-MM-DD");
                formData["to"] = moment().format("YYYY-MM-DD");
                this.store.getAssetDetail(formData, true, this,this.$route.query.targetBrokerAccountId).then(() => {
                    if (!this.store.singleLoading && Object.keys(this.store.assetDetail).length) {
                        this.changeChart(365)
                    }
                });
            },
            drawMarketChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                
                let root = am5.Root.new(id, {
                  useSafeResolution: false
                });

                root.interfaceColors.set("grid", am5.color(0xffffff));


                root.setThemes([am5themes_Animated.new(root)]);
                root.numberFormatter.setAll({
                    numberFormat: "#.##",
                    //   smallNumberThreshold: 0.001
                });
                // Create drawprofitChart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        panX: false,
                        panY: false,
                        wheelX: "panX",
                        wheelY: "zoomX",
                        pinchZoomX:true,
                        draggable:false
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        groupData: true,
                        baseInterval: {
                            timeUnit: this.dateDropSelected.key == 1 ? "hour" : "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 50,
                        }),
                        startLocation: 1,
                        endLocation: 1,
                        tooltip: am5.Tooltip.new(root, {})
                    })
                );

                // xAxis.get("dateFormats")["day"] = "MM/dd";
                // xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({
                    stroke: am5.color(0x666666),
                    strokeWidth: 1,
                });

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                var legend = chart.bottomAxesContainer.children.push(
                    am5.Legend.new(root, {
                        x: am5.percent(50),
                        centerX: am5.percent(50),
                        layout: root.horizontalLayout,
                        useDefaultMarker: true,
                        clickTarget: "none",
                        // paddingTop: 15,
                    })
                );

                legend.labels.template.setAll({
                    fontSize: 12,
                    fontWeight: "600",
                });
                legend.markerRectangles.template.setAll({
                    cornerRadiusTL: 20,
                    cornerRadiusTR: 20,
                    cornerRadiusBL: 20,
                    cornerRadiusBR: 20,
                });
                legend.markers.template.setAll({
                    width: 13,
                    height: 13,
                });

                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        //legendLabelText: this.store.assetDetail.symbol,
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        valueXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: "{valueY}",
                        }),
                    })
                );
                series.fills.template.setAll({
                    visible: true,
                });

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set data
                series.data.setAll(data);
                //legend.data.push(series);

                // Make stuff animate on load
                series.appear(1000);

                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        behavior: "none",
                    })
                );
                cursor.lineY.set("visible", true);
                cursor.lineX.set("visible", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#666666")),
                    fontSize: 10,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#666666")),
                    fontSize: 12,
                }); 

                // var label2 = am5.Label.new(root, {
                //     text: "[#666] Date/Time [/]",
                //     x: am5.p50,
                //     centerY: am5.p50,
                //     fontSize: 12,
                // });
                // xAxis.children.push(label2);
                // var label1 = am5.Label.new(root, {
                //     rotation: -90,
                //     text: "Close Price",
                //     fill: am5.color(0x666666),
                //     y: am5.p50,
                //     centerX: am5.p50,
                //     fontSize: 12,
                // });
                // yAxis.children.unshift(label1);

                chart.appear(1000, 100);
            },
        },
        created() {
            if (this.$route.query.access_token) {
                this.store.$patch({ user: { access_token: this.$route.query.access_token } });
            } 
            if (this.$route.params.id) {
                this.getCurrecnyDetail();
            }
        },
    };
</script>
